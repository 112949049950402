import React from 'react';
import GenderDonut from "./subcomponents/genderdonut";
import AgeBar from "./subcomponents/agebar";
import RevenueBreakdown from "./revenuebreakdown"; // Make sure this path is correct
import { Card } from "@tremor/react";
import Geography from "./geography";

export default function Audience() {
    return (
        <main className="w-full my-8">
            <div className="flex flex-row justify-center gap-8">
                {/* First Column: GenderDonut and AgeBar */}
                <Card className="flex flex-col max-w-sm gap-4 shadow-xl" decoration="top" decorationColor="red">
                    <GenderDonut/>
                    <AgeBar/>
                </Card>

                {/* Second Column: Geography */}
                <Geography/>

                {/* Third Column: RevenueBreakdown */}
                <Card className="flex flex-col max-w-sm gap-4 shadow-xl" decoration="top" decorationColor="red">
                    <RevenueBreakdown/>
                </Card>
            </div>        
        </main>
    );
}
