import React, { useContext, useEffect, useState } from 'react';
import {
  Card,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableBody,
} from "@tremor/react";
import { AuthContext } from '../authcontext'; // If needed for authorization

export default function RecentVideos() {
  const { accessToken } = useContext(AuthContext);
  const [recentVideos, setRecentVideos] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`https://youtube-dashboard-c962d76377d9.herokuapp.com/youtube-metrics/recentVideos?access_token=${accessToken}`);
      const data = await response.json();
  
      setRecentVideos(data.map(video => ({
        videoName: video.videoName, // Using videoName from new data structure
        views: video.views.toLocaleString(),
        watchhours: (video.estimatedMinutesWatched / 60).toFixed(0), // Convert minutes to hours
        avgvd: `${Math.floor(video.averageViewDuration / 60)}:${('0' + (video.averageViewDuration % 60)).slice(-2)}`, // Convert seconds to MM:SS
        subsgained: video.subscribersGained,
        estrev: `$${video.estimatedRevenue.toFixed(2)}`,
        cpm: video.cpm.toFixed(2),
        delta: video.cpm > 1 ? "overperforming" : "underperforming",
        deltaType: video.cpm > 1 ? "moderateIncrease" : "moderateDecrease"
      })));
    };
  
    fetchData().catch(console.error);
  }, [accessToken]);

  return (
    <Card className="h-fit max-w-7xl shadow-xl mb-8" decoration="top" decorationColor="red">
      <Table>
        <TableHead>
            <TableRow>
              <TableHeaderCell className="text-center">Video Name</TableHeaderCell>
              <TableHeaderCell className="text-center">Views</TableHeaderCell>
              <TableHeaderCell className="text-center">Watch Hours</TableHeaderCell>
              <TableHeaderCell className="text-center">Average VD</TableHeaderCell>
              <TableHeaderCell className="text-center">Subs Gained</TableHeaderCell>
              <TableHeaderCell className="text-center">Est. Revenue</TableHeaderCell>
              <TableHeaderCell className="text-center">CPM</TableHeaderCell>
              {/*<TableHeaderCell className="text-center">Overall</TableHeaderCell>*/}
            </TableRow>
          </TableHead>
        <TableBody>
          {recentVideos.map((video, index) => (
            <TableRow key={index}>
              <TableCell className="text-center max-w-xs truncate overflow-hidden">{video.videoName}</TableCell>
              <TableCell className="text-center">{video.views}</TableCell>
              <TableCell className="text-center font-bold">{video.watchhours}</TableCell>
              <TableCell className="text-center">{video.avgvd}</TableCell>
              <TableCell className="text-center">{video.subsgained}</TableCell>
              <TableCell className="text-center">{video.estrev}</TableCell>
              <TableCell className="text-center">{video.cpm}</TableCell>
              {/*<TableCell className="text-center">
                <BadgeDelta deltaType={video.deltaType}>{video.delta}</BadgeDelta>
          </TableCell>*/}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
}
