import React, { useContext, useEffect, useState } from 'react';
import { Card, List, ListItem, ProgressBar, Text, Title, DonutChart } from "@tremor/react";
import { AuthContext } from '../authcontext';

const SharingBreakdown = () => {
  const { accessToken } = useContext(AuthContext);
  const [sharingData, setSharingData] = useState([]);

  useEffect(() => {
    if (accessToken) {
      fetch(`https://youtube-dashboard-c962d76377d9.herokuapp.com/youtube-metrics/sharing?access_token=${accessToken}`)
        .then(response => response.json())
        .then(data => {
          const transformedData = data.rows
            .map(row => ({
              service: formatServiceName(row[0]),
              shares: row[1]
            }))
            .sort((a, b) => b.shares - a.shares)
            .slice(0, 7);

          setSharingData(transformedData);
        })
        .catch(error => console.error('Error fetching sharing data:', error));
    }
  }, [accessToken]);

  const totalShares = sharingData.reduce((total, item) => total + item.shares, 0);

  // Function to format service names
  const formatServiceName = (name) => {
    return name
      .toLowerCase()
      .replace(/_/g, ' ')
      .replace(/\b(\w)/g, char => char.toUpperCase());
  };

  return (
    <div>
      <Card className="max-w-sm h-fit">
        <Title>Shares</Title>
        <DonutChart
          data={sharingData}
          category="shares"
          index="service"
          showAnimation={true}
          showLabel={true}
        />
      </Card>

      <Card className="max-w-lg mt-4">
        <Title>Sharing Details</Title>
        <List className="mt-4">
          {sharingData.map((item) => (
            <ListItem key={item.service}>
              <div className="w-full">
                <Text>{item.service}</Text>
                <ProgressBar
                  value={(item.shares / totalShares) * 100}
                  label={`${((item.shares / totalShares) * 100).toFixed(1)}%`}
                />
              </div>
            </ListItem>
          ))}
        </List>
      </Card>
    </div>
  );
};

export default SharingBreakdown;
