import React, { useState, useEffect } from 'react';
import keyData from './keyreal.json';
import KeyStats from './components/keystats';
import Audience from './components/audience';
import RecentVideos from './components/recentvideos';
import {AuthContext } from './authcontext'; // Import AuthContext here
import { Button } from "@tremor/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';


function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [accessToken, setAccessToken] = useState(null); // State to store the access token

  useEffect(() => {
    if (window.location.hash) {
      const { access_token } = Object.fromEntries(new URLSearchParams(window.location.hash.substring(1)));
      if (access_token) {
        setAccessToken(access_token); // Storing the token in memory
        console.log("Access Token set in App:", access_token); // Check if token is set
        setIsAuthenticated(true);
      }
    }
  }, []);

  const handleLogin = () => {
    const rootUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
    const params = new URLSearchParams({
      client_id: keyData.web.client_id, // Using the client ID from the JSON file
      redirect_uri: keyData.web.redirect_uris[0], // Assuming the first redirect URI is the one you're using
      response_type: 'token',
      scope: 'https://www.googleapis.com/auth/youtube.readonly ' +
             'https://www.googleapis.com/auth/yt-analytics.readonly ' +
             'https://www.googleapis.com/auth/yt-analytics-monetary.readonly',
    });
    window.location = `${rootUrl}?${params}`;
  };

  if (!isAuthenticated) {
    return (
      <div className="flex items-center justify-center h-[100vh] login-container bg-slate-900">
        <Button size="xl" color='red' onClick={handleLogin} className=" px-6 py-3 font-bold text-black">
          <FontAwesomeIcon icon={faGoogle}className="mr-2" /> Login with Google
        </Button>

      </div>
    );
  }

  return (
    <AuthContext.Provider value={{ accessToken, setAccessToken }}>
    <main className="bg-white dark:bg-gray-900 flex min-h-screen flex-col items-center justify-between px-10 py-5">
      <KeyStats />
      
      <Audience />
      <RecentVideos/>
      
    </main>
    </AuthContext.Provider>
  );
}

export default App;
