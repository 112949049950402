import React, { useContext, useEffect, useState } from 'react';
import { Card, List, ListItem, ProgressBar, Text, Title, Grid } from "@tremor/react";
import { AuthContext } from '../../authcontext';

export default function AgeBar() {
  const { accessToken } = useContext(AuthContext);
  const [ageGroups, setAgeGroups] = useState([]);

  useEffect(() => {
    if (accessToken) {
      fetch(`https://youtube-dashboard-c962d76377d9.herokuapp.com/youtube-metrics/ageGroups?access_token=${accessToken}`)
        .then(response => response.json())
        .then(data => {
          // Format the age group labels and use the percentage directly
          const transformedData = data.rows.map(row => {
            const ageGroup = row[0].replace('age', '');
            const percentage = row[1]; // Use the percentage as is
            return { ageGroup, percentage };
          });
          setAgeGroups(transformedData);
        })
        .catch(error => console.error('Error fetching age group data:', error));
    }
  }, [accessToken]);

  return (
    <Grid numItemsSm={2} numItemsLg={1} className="gap-6">
      <Card className="max-w-lg">
        <Title>Age Groups</Title>
        <List className="mt-4">
          {ageGroups.map((group) => (
            <ListItem key={group.ageGroup}>
              <div className="w-full">
                <Text>{group.ageGroup}</Text>
                <ProgressBar
                  value={group.percentage}
                  label={`${group.percentage.toFixed(1)}%`}
                />
              </div>
            </ListItem>
          ))}
        </List>
      </Card>
    </Grid>
  );
}


