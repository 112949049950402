import React, { useContext, useEffect, useState } from 'react';
import { Card, Text, List, ListItem, ProgressBar } from "@tremor/react";
import { AuthContext } from '../authcontext';

const countryCodeToName = {
  'US': 'United States',
  'CN': 'China',
  'IN': 'India',
  'JP': 'Japan',
  'DE': 'Germany',
  'RU': 'Russia',
  'BR': 'Brazil',
  'GB': 'United Kingdom',
  'FR': 'France',
  'IT': 'Italy',
  'CA': 'Canada',
  'KR': 'South Korea',
  'ES': 'Spain',
  'AU': 'Australia',
  'MX': 'Mexico',
  'ID': 'Indonesia',
  'NL': 'Netherlands',
  'TR': 'Turkey',
  'SA': 'Saudi Arabia',
  'CH': 'Switzerland',
  'SE': 'Sweden',
  'NG': 'Nigeria',
  'PL': 'Poland',
  'AR': 'Argentina',
  'BE': 'Belgium',
  'TH': 'Thailand',
  'AT': 'Austria',
  'NO': 'Norway',
  'AE': 'United Arab Emirates',
  'DK': 'Denmark',
  'EG': 'Egypt',
  'JO': 'Jordan',
  'KW': 'Kuwait',
  // Add or remove countries as needed
};


export default function Geography() {
  const { accessToken } = useContext(AuthContext);
  const [byCountry, setByCountry] = useState([]);
  const [byCity, setByCity] = useState([]);

  useEffect(() => {
    if (accessToken) {
      fetch(`https://youtube-dashboard-c962d76377d9.herokuapp.com/youtube-metrics/geography?access_token=${accessToken}`)
        .then(response => response.json())
        .then(data => {
          // Process and set data for byCountry and byCity
          const totalCountryViews = data.byCountry?.rows.reduce((acc, row) => acc + row[1], 0) || 0;
          const totalCityViews = data.byCity?.rows.reduce((acc, row) => acc + row[1], 0) || 0;
  
          const processedByCountry = data.byCountry?.rows
            ?.sort((a, b) => b[1] - a[1])
            .slice(0, 10)
            .map(row => ({
              name: countryCodeToName[row[0]] || row[0],
              share: Math.round((row[1] / totalCountryViews) * 100),
              amount: `${row[1]}`
            })) || [];
          setByCountry(processedByCountry);

          const processedByCity = data.byCity?.rows
            ?.sort((a, b) => b[1] - a[1])
            .slice(0, 10)
            .map(row => ({
              name: row[0],
              share: Math.round((row[1] / totalCityViews) * 100),
              amount: `${row[1]}`
            })) || [];
          setByCity(processedByCity);
        })
        .catch(error => console.error('Error:', error));
    }
  }, [accessToken]);

  const renderCard = (title, data) => (
    <Card className="w-[30vw] shadow-xl" decoration="top" decorationColor="red">
      <Text className="font-semibold">{title}</Text>
      <List className="mt-4">
        {data.map((item, index) => (
          <ListItem key={index}>
            <div className="w-full">
              <Text>{item.name}</Text>
              <ProgressBar
                value={item.share}
                label={`${item.share}%`}
                tooltip={item.amount}
              />
            </div>
          </ListItem>
        ))}
      </List>
    </Card>
  );

  return (
    <section>
      <div className="flex flex-col gap-10">
      {renderCard("Views • By Country", byCountry)}
      {renderCard("Views • By City", byCity)}
      </div>
    </section>
  );
}
