import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Card, Metric, Text, AreaChart, BadgeDelta, Flex, Grid } from "@tremor/react";
import { AuthContext } from '../authcontext';

const valueFormatter = (number) => {
  return `$${number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
};

const numberFormatter = (number) => Intl.NumberFormat("us").format(number).toString();

const convertMinutesToHours = (minutes) => {
  return Math.floor(minutes / 60);
};

export default function KeyStats() {
  const { accessToken } = useContext(AuthContext);

  const [currentViewsData, setCurrentViewsData] = useState([]);
  const [prevViewsData, setPrevViewsData] = useState([]);
  const [currentWatchHoursData, setCurrentWatchHoursData] = useState([]);
  const [prevWatchHoursData, setPrevWatchHoursData] = useState([]);
  const [currentSubscribersData, setCurrentSubscribersData] = useState([]);
  const [prevSubscribersData, setPrevSubscribersData] = useState([]);
  const [currentEstimatedRevData, setCurrentEstimatedRevData] = useState([]);
  const [prevEstimatedRevData, setPrevEstimatedRevData] = useState([]);

  const calculateDelta = (current, previous) => {
    if (previous === 0) return 0;
    return ((current - previous) / previous) * 100;
  };

  // Define startDate and endDate
  const endDate = new Date().toISOString().split('T')[0];
  const startDate = new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0];
  const prevStartDate = new Date(new Date().setDate(new Date().getDate() - 60)).toISOString().split('T')[0];
  const prevEndDate = startDate;

  // Helper function to fetch data
  const fetchViewsData = useCallback((metric, setData, start, end) => {
    fetch(`https://youtube-dashboard-c962d76377d9.herokuapp.com/youtube-metrics/${metric}?access_token=${accessToken}&start_date=${start}&end_date=${end}`)
      .then(response => response.json())
      .then(data => {
        setData(data.rows.map(row => ({ Day: row[0], Value: row[1] })));
      })
      .catch(error => console.error(`Error fetching ${metric} data:`, error));
  }, [accessToken]); // Include any dependencies the function relies on

  // Fetch Views Data
  useEffect(() => {
    if (accessToken) {
      fetchViewsData('views', setCurrentViewsData, startDate, endDate);
      fetchViewsData('views', setPrevViewsData, prevStartDate, prevEndDate);
    }
  }, [accessToken, fetchViewsData, startDate, endDate, prevStartDate, prevEndDate]);

  // Fetch Watch Hours Data
  useEffect(() => {
    if (accessToken) {
      fetchViewsData('watchhours', setCurrentWatchHoursData, startDate, endDate);
      fetchViewsData('watchhours', setPrevWatchHoursData, prevStartDate, prevEndDate);
    }
  }, [accessToken, fetchViewsData, startDate, endDate, prevStartDate, prevEndDate]);

  // Fetch Subscribers Data
  useEffect(() => {
    if (accessToken) {
      fetchViewsData('subscribers', setCurrentSubscribersData, startDate, endDate);
      fetchViewsData('subscribers', setPrevSubscribersData, prevStartDate, prevEndDate);
    }
  }, [accessToken, fetchViewsData, startDate, endDate, prevStartDate, prevEndDate]);

  // Fetch Estimated Revenue Data
  useEffect(() => {
    if (accessToken) {
      fetchViewsData('estimatedRevenue', setCurrentEstimatedRevData, startDate, endDate);
      fetchViewsData('estimatedRevenue', setPrevEstimatedRevData, prevStartDate, prevEndDate);
    }
  }, [accessToken, fetchViewsData, startDate, endDate, prevStartDate, prevEndDate]);

  

  // Calculations for totals and deltas
  const totalCurrentViews = currentViewsData.reduce((acc, row) => acc + row.Value, 0);
  const totalPrevViews = prevViewsData.reduce((acc, row) => acc + row.Value, 0);
  // Modify this section to handle watch hours data
  const totalCurrentWatchHours = convertMinutesToHours(currentWatchHoursData.reduce((acc, row) => acc + row.Value, 0));
  const totalPrevWatchHours = convertMinutesToHours(prevWatchHoursData.reduce((acc, row) => acc + row.Value, 0));
  const totalCurrentSubscribers = currentSubscribersData.reduce((acc, row) => acc + row.Value, 0);
  const totalPrevSubscribers = prevSubscribersData.reduce((acc, row) => acc + row.Value, 0);
  const totalCurrentEstimatedRev = currentEstimatedRevData.reduce((acc, row) => acc + row.Value, 0);
  const totalPrevEstimatedRev = prevEstimatedRevData.reduce((acc, row) => acc + row.Value, 0);

  const viewsDelta = calculateDelta(totalCurrentViews, totalPrevViews);
  const watchHoursDelta = calculateDelta(totalCurrentWatchHours, totalPrevWatchHours);
  const subscribersDelta = calculateDelta(totalCurrentSubscribers, totalPrevSubscribers);
  const estimatedRevDelta = calculateDelta(totalCurrentEstimatedRev, totalPrevEstimatedRev);

  return (
    <Grid numItemsSm={2} numItemsLg={4} className="gap-8 mt-4">
      {/* Card for Views */}
      <Card className="w-[21vw] shadow-xl" decoration="top" decorationColor="red">
        <Flex alignItems="start">
          <Text className="text-semibold">Views</Text>
          <BadgeDelta deltaType={viewsDelta >= 0 ? "moderateIncrease" : "moderateDecrease"}>
            {viewsDelta.toFixed(1)}%
          </BadgeDelta>
        </Flex> 
        <Flex className="space-x-3 truncate" justifyContent="start" alignItems="baseline">
          <Metric>{totalCurrentViews.toLocaleString()}</Metric> {/* Display total current views */}
          <Text>Last 28 Days</Text>
        </Flex>
        <AreaChart
          className="mt-6 h-28"
          data={currentViewsData}
          index="Day"
          valueFormatter={numberFormatter}
          categories={["Value"]}
          colors={["blue"]}
          showXAxis={true}
          showGridLines={false}
          startEndOnly={true}
          showYAxis={false}
          showLegend={false}
          showAnimation={true}
        />
      </Card>

      <Card className="w-[21vw] shadow-xl" decoration="top" decorationColor="red">
        <Flex alignItems="start">
          <Text className="text-semibold">Watch Hours</Text>
          <BadgeDelta deltaType={watchHoursDelta >= 0 ? "moderateIncrease" : "moderateDecrease"}>
            {watchHoursDelta.toFixed(1)}%
          </BadgeDelta>
        </Flex>
        <Flex className="space-x-3 truncate" justifyContent="start" alignItems="baseline">
          <Metric>{totalCurrentWatchHours.toLocaleString()}</Metric> {/* Display total current watch hours */}
          <Text>Last 28 Days</Text>
        </Flex>
        <AreaChart
          className="mt-6 h-28"
          data={currentWatchHoursData.map(item => ({ ...item, Value: convertMinutesToHours(item.Value) }))}
          index="Day"
          valueFormatter={numberFormatter}
          categories={["Value"]}
          colors={["blue"]}
          showXAxis={true}
          showGridLines={false}
          startEndOnly={true}
          showYAxis={false}
          showLegend={false}
          showAnimation={true}
        />
      </Card>

      <Card className="w-[21vw] shadow-xl" decoration="top" decorationColor="red">
        <Flex alignItems="start">
          <Text className="text-semibold">Subscribers</Text>
          <BadgeDelta deltaType={subscribersDelta >= 0 ? "moderateIncrease" : "moderateDecrease"}>
            {subscribersDelta.toFixed(1)}%
          </BadgeDelta>
        </Flex>
        <Flex className="space-x-3 truncate" justifyContent="start" alignItems="baseline">
          <Metric>{totalCurrentSubscribers.toLocaleString()}</Metric>
          <Text>Last 28 Days</Text>
        </Flex>
        <AreaChart
          className="mt-6 h-28"
          data={currentSubscribersData}
          index="Day"
          valueFormatter={numberFormatter}
          categories={["Value"]}
          colors={["blue"]}
          showXAxis={true}
          showGridLines={false}
          startEndOnly={true}
          showYAxis={false}
          showLegend={false}
          showAnimation={true}
        />
      </Card>

      <Card className="w-[21vw] shadow-xl" decoration="top" decorationColor="red">
        <Flex alignItems="start">
          <Text className="text-semibold">Estimated Revenue</Text>
          <BadgeDelta deltaType={estimatedRevDelta >= 0 ? "moderateIncrease" : "moderateDecrease"}>
            {estimatedRevDelta.toFixed(1)}%
          </BadgeDelta>
        </Flex>
        <Flex className="space-x-3 truncate" justifyContent="start" alignItems="baseline">
          <Metric>{valueFormatter(totalCurrentEstimatedRev)}</Metric> {/* Format total estimated revenue as currency */}
          <Text>Last 28 Days</Text>
        </Flex>
        <AreaChart
          className="mt-6 h-28"
          data={currentEstimatedRevData.map(item => ({ ...item, Value: parseFloat(valueFormatter(item.Value).replace(/[^0-9.-]+/g,"")) }))}
          index="Day"
          valueFormatter={(value) => valueFormatter(value)}
          categories={["Value"]}
          colors={["green"]}
          showXAxis={true}
          showGridLines={false}
          startEndOnly={true}
          showYAxis={false}
          showLegend={false}
          showAnimation={true}
        />
      </Card>
    </Grid>
  );
}
