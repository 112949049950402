import React, { useContext, useEffect, useState } from 'react';
import { Card, DonutChart, Title } from "@tremor/react";
import { AuthContext } from '../../authcontext';

const GenderDonut = () => {
  const { accessToken } = useContext(AuthContext);
  const [genderData, setGenderData] = useState([]);

  useEffect(() => {
    if (accessToken) {
      fetch(`https://youtube-dashboard-c962d76377d9.herokuapp.com/youtube-metrics/gender?access_token=${accessToken}`)
        .then(response => response.json())
        .then(data => {
          // Transform the API data to the format required by DonutChart
          const transformedData = data.rows.map(row => ({
            gender: row[0],
            views: row[1]
          }));
          setGenderData(transformedData);
        })
        .catch(error => console.error('Error fetching gender data:', error));
    }
  }, [accessToken]);

  return (
    <Card className="max-w-sm h-fit">
      <Title>Gender</Title>
      <DonutChart
        data={genderData}
        category="views"
        index="gender"
        showAnimation={true}
        colors={["pink", "blue"]}
        showLabel={false}
      />
    </Card>
  );
};

export default GenderDonut;
